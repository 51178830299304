/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
// node modules
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

// app: components
import Link from 'components/Link';

import showIntercomMessagesHelper from 'api/helpers/showIntercomMessagesHelper';

// assets
import CssNotFound from './NotFound.scss';

class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <img
          className="not-found__image margin-bottom-30"
          src="images/fridge.png"
          alt="fridge"
          height="400"
          width="250"
        />

        <header className="margin-bottom-30">
          <h1 className="not-found__heading">When she got there, the cupboard was bare...</h1>
        </header>

        <p className="not-found__paragraph">
          Sorry - we couldn't find what you were looking for.{' '}
          <Link
            className="link link--energized"
            to=""
            onClick={evt => {
              evt.stopPropagation();
              if (window.Intercom) {
                evt.preventDefault();
                window.Intercom('show');
              } else if (window.intercom) {
                showIntercomMessagesHelper();
              }
            }}
          >
            Let us know
          </Link>{' '}
          if you spotted a broken link. Or go back to the{' '}
          <Link className="link link--energized" to="/">
            home page.
          </Link>
        </p>
      </div>
    );
  }
}

export default withStyles(CssNotFound)(NotFound);
