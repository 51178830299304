import { openWindow } from 'services/inAppBrowser';
import { isIosApp } from 'services/environment';

const showIntercomMessagesHelper = (message = '') => {
  if (isIosApp()) {
    openWindow('https://www.ckbk.com/contact/', '_blank');
    return;
  }

  if (!window || !window.intercom) {
    console.error('window.intercom not available');
    return;
  }

  try {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message);
    } else if (window.intercom) {
      window.intercom.displayMessageComposerWithInitialMessage(message);
    }
  } catch (e) {
    console.error('window.intercom.displayMessageComposerWithInitialMessage not available', e);
  }
};

export default showIntercomMessagesHelper;
