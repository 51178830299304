export function hideWindow() {
  if (window && window.SafariViewController) {
    window.SafariViewController.hide();
  }
}

export async function openWindow(url, options) {
  return new Promise((resolve, reject) => {
    if (!(window && window.SafariViewController)) {
      window.open(url, '_blank');
      resolve();
      return;
    }

    const browser = window.SafariViewController;
    browser.isAvailable(available => {
      const safariOptions = typeof options === 'object' ? options : {};
      if (available) {
        browser.show(
          {
            url,
            ...safariOptions,
          },
          result => {
            if (result.event === 'opened') {
              resolve(result);
            }
          },
          err => {
            console.error(`InAppBrowser window sent error: ${JSON.stringify(err)}`);
            reject(err);
          },
        );
      } else {
        window.open(url, '_system');
        resolve();
      }
    });
  });
}

export default { hideWindow, openWindow };
